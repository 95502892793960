// 赛事活动 相关路由
export const activities = [
    {
        path: '/activities',
        name: 'Activities',
        component: () => import('@/views/activities/Index.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/mobile/activities',
        name: 'MobileActivities',
        component: () => import('@/views/activities/IndexM.vue'),
        meta: { requiresAuth: false },
    }
]