<template>
    <div class="m-header-box">
        <img src="@/assets/images/logo_m.png" class="logo" />
        <img
            v-if="!openFlag"
            src="@/assets/images/index/mobile/menu@2x.png"
            class="menu"
            @click="openMenu" />
        <img
            v-else
            src="@/assets/images/index/mobile/close@2x.png"
            class="menu"
            @click="openFlag=false" />



        <el-drawer
            :visible.sync="openFlag"
            direction="rtl"
            class="menu-drawer"
            custom-class="menu-drawer-inner"
            ref="drawer"
            size="3.22rem"
            :withHeader="false"
            :append-to-body="true">
                <ul
                    class="menu-collapse">
                    <li
                        v-for="(m, mdx) in menuList"
                        :key="mdx"
                        :title="m.name"
                        class="menu-item"
                        :class="($route.matched.some(r => r.path == m.url) || $route.path == m.url) ? 'current' : ''"
                        @click="openPage(m)">
                            <span class="menu-name">{{ m.name }}</span>
                            <i class="iconfont "></i>
                    </li>
                </ul>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name: "MobilePageHeader",
    data() {
        return {
            openFlag: false,
            menuList: [
                {url: "/mobile", name: "首页"},
                {url: "/mobile/activities", name: "赛事活动"},
                {url: "/mobile/intro", name: "赛项介绍"},
                {url: "/mobile/lesson", name: "在线课程"},
                {url: "/mobile/resource", name: "资源中心"},
                {url: "/mobile/news", name: "新闻中心"},
            ]
        }
    },
    methods: {
        openMenu() {
            this.openFlag = true
        },
        // 打开对应的菜单页面
        openPage(menu) {
            this.$router.push({
                path: menu.url
            })
            this.openFlag = false
        },
    }
}
</script>
<style scoped lang="scss">
.m-header-box {
    z-index: 9999;
    position: fixed;
    height: 0.56rem;
    background: #FFFFFF;
    padding: 0.1rem 0.16rem;
    justify-content: space-between;
    display: flex;
    width: 100%;
    box-shadow: 0rem 0.03rem 0.06rem 0.01rem rgba(21,24,29,0.05);
    .logo {
        // width: 1.62rem;
        height: 0.36rem;
    }
    .menu {
        width: 0.24rem;
        height: 0.24rem;
        margin-top: 0.06rem;
    }
}
</style>
<style lang="scss">
.menu-drawer {
    .menu-drawer-inner {
        box-shadow: none !important;
        padding-top: 0.56rem;
        .menu-collapse {
            padding: 0.04rem 0.24rem 0.04rem 0.24rem;
            .menu-item {
                height: 0.56rem;
                line-height: 0.56rem;
                border-bottom: 0.01rem solid #F5F7FC;
                font-size: 0.16rem;
                color: #15181D;
                font-weight: SourceHanSansCN-Bold;
                display: flex;
                justify-content: space-between;
                .menu-name {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.current {
                    color: #EB6B6A;
                }
            }
        }
    }
}
</style>