import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/permission.js'
import '@/utils/rem.js'
// import '@/utils/flexible.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies'
import '@/assets/style/elementui.scss'
import _ from 'lodash'

// import "amfe-flexible"
// import 'lib-flexible/flexible'


Vue.prototype.$EventBus = new Vue()
Vue.prototype.$_ = _
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueCookies)


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
