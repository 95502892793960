// 资源中心 相关路由
export const resource = [
    {
        path: '/resource',
        name: 'Resource',
        component: () => import('@/views/resource/Index.vue'),
        meta: { requiresAuth: false },
        redirect: '/resource/list',
        children: [
            {
                path: 'list',
                name: 'ResourceList',
                component: () => import('@/views/resource/List.vue'),
            },
            {
                path: 'detail/:id',
                name: 'ResourceDetail',
                component: () => import('@/views/resource/Detail.vue'),
            },
        ]
    },
    {
        path: '/mobile/resource',
        name: 'MobileResource',
        component: () => import('@/views/resource/IndexM.vue'),
        meta: { requiresAuth: false },
        redirect: '/mobile/resource/list',
        children: [
            {
                path: 'list',
                name: 'MobileResourceList',
                component: () => import('@/views/resource/ListM.vue'),
            },
            {
                path: 'detail/:id',
                name: 'MobileResourceDetail',
                component: () => import('@/views/resource/DetailM.vue'),
            },
        ]
    }
]