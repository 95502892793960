import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './router.js' // 引入router.js的输出

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(Router);

export default new Router({
    fallback: false,
    // base: '/test/',
    mode: 'history',
    routes: routes
})
