// 在线课程
export const lesson = [
    {
        path: '/lesson',
        name: 'Lesson',
        component: () => import('@/views/lesson/Index.vue'),
        redirect: '/lesson/list',
        meta: { requiresAuth: false},
        children: [
            {
                path: 'list',
                name: 'LessonList',
                component: () => import('@/views/lesson/List.vue'),
                meta: { requiresAuth: false},
            },
            {
                path: 'detail/:id',
                name: 'LessonDetail',
                component: () => import('@/views/lesson/Detail.vue'),
                meta: { requiresAuth: false},
            },
        ]
    },
    {
        path: '/mobile/lesson',
        name: 'MobileLesson',
        component: () => import('@/views/lesson/IndexM.vue'),
        redirect: '/mobile/lesson/list',
        meta: { requiresAuth: false},
        children: [
            {
                path: 'list',
                name: 'LessonList',
                component: () => import('@/views/lesson/ListM.vue'),
                meta: { requiresAuth: false},
            },
            {
                path: 'detail/:id',
                name: 'MobileLessonDetail',
                component: () => import('@/views/lesson/DetailM.vue'),
                meta: { requiresAuth: false},
            }
        ]
    },

]