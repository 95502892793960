import { index } from './modules/index.js'
import { work } from './modules/work.js'
import { lesson } from './modules/lesson.js'
import { classInfo } from './modules/class.js'
import { intro } from './modules/intro.js'
import { news } from './modules/news.js'
import { resource } from './modules/resource.js'
import { activities } from './modules/activities.js'
import { python } from './modules/python.js'

// 数组形式 输出路由
export const routes = [
    ...index,
    ...work,
    ...lesson,
    ...classInfo,
    ...intro,
    ...news,
    ...resource,
    ...activities,
    ...python,

    {
        path: '*',
        name: '404',
        component: () => import('@/views/404/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/mobile/*',
        name: '404',
        component: () => import('@/views/404/IndexM.vue'),
        meta: { requiresAuth: false }
    },
]