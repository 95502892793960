const state = {
  dict: [],
}

const mutations = {
  setDict(state, dict) {
    state.dict = dict
  },
}

const actions = {
  // 获取字典
  getDict(context, _key) {
    let res = null
    if (_key == null && _key == "") {
        res = null;
    }
    try {
      for (let i = 0; i < state.dict.length; i++) {
        if (state.dict[i].key == _key) {
            res = state.dict[i].value;
        }
      }
    } catch (e) {
        res = null;
    }
    return res
  },
  // 设置字典
  setDict(context, data) {
    let newDict = state.dict
    if (data._key !== null && data._key !== "") {
        newDict.push({
            key: data._key,
            value: data.value
        });
    }
    context.commit('setDict', newDict)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}