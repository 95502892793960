
import Vue from 'vue'
const TokenKey = 'Admin-Token';

// 获取token
export const getToken = () => {
    return Vue.prototype.$cookies.get(TokenKey)
}

// 设置token
export const setToken = (access_token) => {
    Vue.prototype.$cookies.set(TokenKey, access_token)
}

// 删除指定名称cookie
export const removeToken = () => {
    Vue.prototype.$cookies.set(TokenKey)
}