<template>
  <div class="page-header">
    <!-- logo部分 -->
    <div class="page-header-logo">
      <a class="page-header-logo-coding" href="/"></a>
    </div>
    <div class="intro-page">
      <div class="right-header">
        <!-- 赛项介绍页对顶部导航栏有个模糊背景样式 -->
        <div class="bg-top" v-if="($route.matched.some(r => r.path == '/intro'))"></div>
        <!-- 顶部菜单 -->
        <ul class="page-header-menu">
          <li
            v-for="(m, mdx) in menuList"
            :key="mdx"
            :title="m.name"
            class="page-header-menu-item"
            :class="($route.matched.some(r => r.path == m.url) || $route.path == m.url) ? 'current' : ''">
              <router-link
                  :to="{ path: m.url }"
                  class="page-header-menu-item-link">
                  {{ m.name }}
              </router-link>
          </li>
        </ul>
        <!-- 个人中心 -->
        <div v-if="!userInfo.nickName" class="page-header-toolbar" @click="openLogin" title="登录">
          登录
        </div>
        <!-- 个人中心 -->
        <div v-if="userInfo && userInfo.nickName" class="page-header-user" :title="userInfo.nickName">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">{{ userInfo.nickName.slice(0, 2) }}</span>
            <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
              <el-dropdown-item title="赛事报名" command="registr">赛事报名</el-dropdown-item>
              <el-dropdown-item title="进入后台" command="goSys">进入后台</el-dropdown-item>
              <!-- <el-dropdown-item title="进入后台" command="teacher">教师后台</el-dropdown-item>
              <el-dropdown-item title="进入后台" command="student">学生后台</el-dropdown-item>-->
              <el-dropdown-item divided title="退出登录" command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { mapState } from 'vuex'

export default {
  name: 'PageHeader',
  components: {
    // Login
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  data() {
    return {
      menuList: [
        {url: "/", name: "首页"},
        {url: "/activities", name: "赛事活动"},
        {url: "/intro", name: "赛项介绍"},
        {url: "/lesson", name: "在线课程"},
        {url: "/resource", name: "资源中心"},
        {url: "/news", name: "新闻中心"},
      ],
      currentMenu: "/", // 当前选中的菜单序号
    }
  },
  mounted() {
    console.log(this.userInfo)
      return false
  },
  methods: {
    // 打开登录弹窗
    openLogin() {
      // this.$router.push({
      //   path: "/login"
      // })
      window.open(this.$router.resolve({name: 'Login'}).href, '_blank');
    },
    gotoPage(url) {
      this.$router.push({
        path: url
      })
    },
    handleCommand(command) {
      switch(command) {
        case "registr": // 赛事报名
          // 游客登录 只能查看报名信息及参与报名
          // 教师登录，没有绑定学校时，只能查看报名信息及参与报名
          // 跳转到赛事活动页面
          this.$router.push({
            path: "/activities"
          })
          break;
        case "goSys": // 进入后台
          console.log(this.$store.state.user.roles)
            // 超级管理员角色SuperAdminRole | SchoolTeacher学校老师 | 学校管理员SchoolAdmin | 学生SchoolStudent
            if (this.$store.state.user.userInfo.userType == "sys_user") { // 超级管理员、学校管理员 跳转到后台管理系统
                // 关闭父窗口，当前页面跳转到官网首页
                // window.opener.close()
                window.open("http://tangnian.natapp1.cc/login?redirect=/index")
            } else if (this.$store.state.user.userInfo.userType == "sys_teacher") { // 跳转到教师端
                window.open(process.env.VUE_APP_TEACHER_URL + "?key=" + getToken())
            } else if (this.$store.state.user.userInfo.userType == "sys_student"){ // 跳转到学生端
                window.open(process.env.VUE_APP_STUDENT_URL + "?key=" + getToken())
            }
            break;
        // case "teacher":
        //     window.open(process.env.VUE_APP_TEACHER_URL + "?key=" + getToken())
        //   break;
        // case "student":
        // window.open(process.env.VUE_APP_STUDENT_URL + "?key=" + getToken())
        //   break;
        case "logout": // 退出登录
            this.$confirm('确认注销并退出系统吗？', '确认退出' , {
                    confirmButtonText: '确认',
                    cancelButtonText: '再想想',
                    type: 'warning',
                    center: true,
                    customClass: "confirm-message"
                }).then(() => {
                  this.$store.dispatch("user/logout")
                })
            break;
        default: break;
      }
    },
  },
  watch: {
    // token变化 更新顶部菜单
    "$store.getters.token": {
      handler(n, o) {

      },
      immediate: true
    }
  },
}
</script>

<style scoped lang="scss">
.page-header {
  height: 0.8rem;
  width: 100%;
  background-color: #fff;
  padding: 0 0 0 0.32rem;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      height: 12rem;
      z-index: 0;
      filter: blur(0.18rem);
  }
  .page-header-logo {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .page-header-logo-coding {
        background-image: url('../../assets/images/logo.png');
        width: 1.28rem;
        height: 0.48rem;
        background-size: 1.28rem 0.48rem;
    }
  }
  .intro-page {
    flex: 1;
    overflow: hidden;
    .right-header {
      float: right;
      position: relative;
      min-width: 8rem;
      display: flex;
      .bg-top {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #B6D0E2;
        -webkit-filter: blur(0.18rem);
        -moz-filter: blur(0.18rem);
        -o-filter: blur(0.18rem);
        -ms-filter: blur(0.18rem);
        filter: blur(0.18rem);
        z-index: 0;
      }
      .page-header-menu {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        align-items: center;
        justify-content: right;
        z-index: 1;
        .page-header-menu-item {
          position: relative;
          .page-header-menu-item-link {
            color: #15181D;
            padding: 0 0.24rem;
            line-height: 0.8rem;
            display: block;
            cursor: pointer;
            font-size: 0.16rem;
          }
          &.current, &:hover {
            &:after {
              content: "";
              position: absolute;
              margin-left: 50%;
              left: -0.16rem;
              bottom: 0;
              width: 0.32rem;
              height: 0.02rem;
              background: #EB6B6A;
              border-radius: 0rem 0rem 0rem 0rem;
            }
          }
        }
      }
      .page-header-toolbar {
        line-height: 0.4rem;
        margin-top: 0.2rem;
        cursor: pointer;
        font-size: 0.16rem;
        color: #EB6B6A;
        font-family: SourceHanSansCN-Bold;
        margin-right: 0.32rem;
        margin-left: 0.96rem;
      }
       .page-header-user {
        width: 0.4rem;
        height: 0.4rem;
        background: #EB6B6A;
        border-radius: 50%;
        text-align: center;
        line-height: 0.4rem;
        margin-top: 0.2rem;
        cursor: pointer;
        overflow: hidden;
        margin-right: 0.32rem;
        /deep/ .el-dropdown-link {
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.user-info-dropdown {
  width: 2.54rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.03rem 0.4rem 0.01rem rgba(21,24,29,0.05);
  border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
  .el-dropdown-menu__item {
    height: 0.48rem;
    line-height: 0.48rem;
    font-size: 0.16rem;
    color: #15181D;
    margin: 0 0.16rem;
    padding: 0 0.16rem;
    &:hover {
      background-color: #EB6B6A !important;
      border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
      color: #FFFFFF !important;
    }
  }
  .el-dropdown-menu__item--divided:before {
    display: none;
  }
}
</style>
