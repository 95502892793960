<template>
  <div class="page-footer">
    <div class="page-footer-container">
        <p class="page-footer-copyright">Copyright © 1998 - 2024 anunnaki. All Rights Reserved. 辛巴创客有限公司 版权所有</p>
        <p class="page-footer-links">
            <router-link :to="{path: '/'}" class="page-footer-links-text">服务协议</router-link>
            <span class="page-footer-line">/</span>
            <router-link :to="{path: '/'}" class="page-footer-links-text">隐私政策</router-link>
            <span class="page-footer-line">/</span>
            <router-link :to="{path: '/'}"s class="page-footer-links-text">版权保护投诉指引</router-link>
        </p>
    </div>
    <div class="contact-box">
        <el-popover
            placement="top-start"
            trigger="hover"
            popper-class="page-footer-hover">
            <div class="phone-hover">
                <p class="phone-title">联系我们</p>
                <div class="phone-detail">
                    <p>电话：<span class="content-txt">028-85231866</span>
                        <span class="iconfont icon-fuzhi" title="点击复制" @click="onCopy('028-85231866')"></span>
                    </p>
                    <p>手机：<span class="content-txt">181 6125 5681</span>
                        <span class="iconfont icon-fuzhi" title="点击复制" @click="onCopy('181 6125 5681')"></span>
                    </p>
                </div>
                <p style="margin-top: 0.32rem;">办公时间：<span class="content-txt">9:00AM-18:00PM</span></p>
                <p style="margin-top: 0.24rem;">公司地址：<span class="content-txt">成都市武侯区天府一街中州锦成湖岸670号</span></p>
            </div>
            <span slot="reference" class="contact-item"><img src="@/assets/images/footer/phone@2x.png" /></span>
        </el-popover>
        <el-popover
            placement="top-start"
            trigger="hover"
            popper-class="page-footer-hover">
            <div class="public-hover">
                <img src="@/assets/images/footer/public_code@2x.png" />
                <div class="public-detail">
                    <p style="margin-bottom: 0.16rem;">关注【ai创想空间】</p>
                    <p>获取最新资讯与优惠！</p>
                    <p class="opt-code"> << 扫码关注</p>
                </div>
            </div>
            <span slot="reference" class="contact-item"><img src="@/assets/images/footer/public@2x.png" /></span>
        </el-popover>
        <span class="contact-item"><img src="@/assets/images/footer/weixin@2x.png" /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {

    }
  },
  methods: {
    copyToClip(content){
        let aux = document.createElement("input")
        aux.setAttribute("value", content)
        document.body.appendChild(aux);
        aux.select()
        document.execCommand("copy")
        document.body.removeChild(aux)
    },
    onCopy(str) {
        this.copyToClip(str)
        this.$message({
            type: "success",
            message: "复制成功!"
        })
    },

  }
}
</script>

<style scoped lang="scss">
.page-footer {
    background: #fff;
    height: 2.97rem;
    position: relative;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #15181D;
    display: flex;
    z-index: 3;
    .page-footer-container {
        width: 100%;
        .page-footer-copyright {
            margin-bottom: 0.16rem;
        }
        .page-footer-links {
            .page-footer-links-text {

            }
            .page-footer-line {
                margin: 0 0.1rem;
            }
        }
    }
    .contact-box {
        position: absolute;
        top: -0.3rem;
        left: 0;
        right: 0;
        text-align: center;
        .contact-item {
            margin: 0 0.215rem;
            img {
                width: 0.6rem;
                height: 0.6rem;
            }
        }
    }
}
</style>
<style lang="scss">
.page-footer-hover {
    background: #FFFFFF;
    box-shadow: 0rem 0.03rem 0.4rem 0.01rem rgba(21,24,29,0.05);
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
    border: none;
    .phone-hover {
        padding: 0.24rem 0.32rem 0.32rem 0.32rem;
        .phone-title {
            font-weight: bold;
            font-size: 0.16rem;
            color: #498AB7;
            margin-bottom: 0.24rem;
        }
        p {
            font-weight: 400;
            font-size: 0.16rem;
            color: #858A99;
            .content-txt {
                color: #15181D;
            }
            .icon-fuzhi {
                float: right;
                cursor: pointer;
                font-size: 0.24rem;
                &:hover {
                    color: #EB6B6A;
                }
            }
        }
        .phone-detail {
            background: #F5F7FC;
            border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
            line-height: 0.64rem;
            padding: 0 0.16rem;
            >p {
                height: 0.64rem;
                &:first-of-type {
                    border-bottom: 0.01rem solid #fff;
                }
            }
        }
    }
    .public-hover {
        padding: 0.4rem;
        display: flex;
        img {
            width: 1.28rem;
            height: 1.28rem;
            margin-right: 0.24rem;
        }
        .public-detail {
            padding-top: 0.24rem;
            font-weight: bold;
            font-size: 0.16rem;
            color: #15181D;
            line-height: 1;
            .opt-code {
                color: #EB6B6A;
                margin-top: 0.24rem;
            }
        }
    }
}
</style>