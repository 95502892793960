// 赛项介绍 相关路由
export const intro = [
    {
        path: '/intro',
        name: 'Intro',
        component: () => import('@/views/introduction/Index.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/mobile/intro',
        name: 'MobileIntro',
        component: () => import('@/views/introduction/IndexM.vue'),
        meta: { requiresAuth: true }
    }
]