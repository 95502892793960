import { isMobile } from '@/utils/index'

// 等比缩放字体大小
const baseSize = 100 // 基准大小
window.onresize = function(){
    let base = 1920
    if (isMobile()) {
        base = 375
    }
    const scale = document.documentElement.clientWidth / base * 100
    // 最高缩放比例为2
    document.documentElement.style.fontSize = scale + 'px';
};
window.onresize();