<template>
  <div id="app">
    <template v-if="isMobile">
      <page-header-m></page-header-m>
      <div class="mobile-container">
        <router-view></router-view>
      </div>
    </template>
    <template v-else>
      <!-- 登录页不显示顶部导航 -->
      <page-header v-if="isShowHeader"></page-header>
      <div
        class="page-container"
        :class="isShowHeader ? '' : 'full-page-container'">
        <router-view></router-view>
      </div>
    </template>
  </div>
</template>

<script>
import { isMobile } from '@/utils/index'
import PageHeader from '@/components/layout/PageHeader'
import PageHeaderM from '@/components/layout/PageHeaderM'
import PageFooter from '@/components/layout/PageFooter'
export default {
  name: 'App',
  components: {
    PageHeader,
    PageHeaderM,
    PageFooter
  },
  data() {
    return {
      introFlag: false, // 是否在赛项页面并且已向下滚动鼠标
    }
  },
  mounted() {
    this.$EventBus.$on("changeIntroFlag", (flag) => {
      this.introFlag = flag
    })
  },
  computed: {
    // 判断是否是移动端
    isMobile() {
      return isMobile()
    },
    // 是滞显示顶部导航栏
    isShowHeader() {
      // 登录页不显示顶部导航栏
      if (this.$route.path == "/login") {
        return false
      }
      // 赛项介绍、新闻中心页面发生滚动时不显示顶部导航栏，顶部导航栏默认显示赛项介绍的类目
      if (this.introFlag) {
        return false
      }
      // python编辑页
      if (this.$route.path == "/py") {
        return false
      }
      return true
    }
  },
  methods: {

  },
  watch: {
    '$route.path': function(newPath, oldPath) {
      // 路由切换时，清除在线课程、资源中心缓存的已选中的学段和分类
      if (newPath.indexOf("/lesson") == -1 && newPath.indexOf("/resource") == -1) {
        localStorage.removeItem("lessonPageInfo")
        localStorage.removeItem("resourcePageInfo")
      }

      if (newPath != '/intro') {
        this.introFlag = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
#app {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  color: #4a596f;
  overflow: hidden;
  position: relative;
  .mobile-container {
    padding-top: 0.56rem;
    height: 100%;
    background: #F5F7FC;
    overflow: hidden;
    /* 隐藏垂直滚动条 */
    ::-webkit-scrollbar {
        display: none;
    }
  }
  .page-container {
    overflow: auto;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    &.full-page-container {
      padding-top: 0;
    }
    // flex: 1;
    // overflow: hidden;
  }
}
</style>
