import { setToken, removeToken } from '@/utils/auth'
import { login, logout, getUserInfo } from '@/api/user'
import router from '@/router'
const state = {
  userInfo: {},
  roles: [],
  permissions: []
}

const mutations = {
  setUserInfo(state, newInfo) {
    state.userInfo = newInfo
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  }
}

const actions = {
  // 登录
  login(context, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        setToken(res.data.access_token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户信息
  getUserInfo(context) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const data = res.data;
        if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          context.commit('setRoles', data.roles)
          context.commit('setPermissions',  data.permissions)
        } else {
          context.commit('setRoles', ['ROLE_DEFAULT'])
        }
        context.commit('setUserInfo', data.user)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 退出登录
  logout(context) {
    logout().then(res => {
      removeToken()
      context.commit('setUserInfo', {})
      context.commit('setRoles', [])
      context.commit('setPermissions', [])
      router.push({ path: "/" })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}