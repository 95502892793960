import store from '@/store'
import { getDicts } from '@/api/dict'

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

// 防抖函数
export const debounce = (fn, wait) => {
    let timeout = null;
    return function() {
        let context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(context, args);
        }, wait);
    };
}

/**
 * 手机号校验
 * @param {phone} phone 手机号
 */
export function phoneCheck(phone) {
  return /^1\d{10}$/.test(phone)
}

/**
 * 验证身份证格式
 * @param {sId} sId 身份证号
 */
export function IdentityCodeValid(sId) {
  const aCity = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外" };
  var iSum = 0;
  if (!/^\d{17}(\d|X|x)$/i.test(sId)) return false;
  sId = sId.replace(/x$/i, "a");
  if (aCity[parseInt(sId.substr(0, 2))] == null) return false;
  var sBirthday = sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2));
  var d = new Date(sBirthday.replace(/-/g, "/"));
  if (sBirthday != (d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate())) return false;
  for (var i = 17; i >= 0; i--) iSum += (Math.pow(2, i) % 11) * parseInt(sId.charAt(17 - i), 11);
  if (iSum % 11 != 1) return false;
  return true;
}

// 将时间转化为时分秒
export function formatSeconds (theTime) {
  let theTime1 = 0
  let theTime2 = 0
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  function formatNum(num) {
    if (num < 10) {
      return "0" + num
    }
    return num
  }
  return {
    hour: formatNum(parseInt(theTime2)),
    min: formatNum(parseInt(theTime1)),
    second: formatNum(parseInt(theTime))
  }
}

/**
 * 判断当前平台是PC还是移动端
 */
export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

/**
 * 批量获取字典数据
 */
export function batchGetDict(args) {
  let res = {}
  return Promise.all(args.map(dictType => {
      return new Promise( async (resolve, reject) => {
          let dicts = store.dispatch("dict/getDict", dictType)
          if (dicts && dicts.length > 0) {
              res[dictType] = dicts;
          } else {
              await getDicts(dictType).then(resp => {
                  res[dictType] = resp.data.map(p => ({
                      label: p.dictLabel,
                      value: p.dictValue,
                      elTagType: p.listClass,
                      elTagClass: p.cssClass
                  }))
                  store.dispatch("dict/setDict", {
                    _key: dictType,
                    value: res[dictType]
                  })
              })
          }
          resolve(res)
      })
  }))
}


// 获取资源的类型图片
export function getResourceTypIcon(item) {
  if (item.suffix) {
      switch (item.suffix.toLowerCase()) {
          case ".ppt":
          case ".pptx":
              return 'ppt@2x.png'
          case ".doc":
          case ".docx":
              return 'docx@2x.png'
          case ".mp4":
              return 'video@2x.png'
          case ".pdf":
              return 'pdf@2x.png'
          case ".zip":
              return 'zip@2x.png'
          case ".excel":
              return 'excel@2x.png'
          case ".mblock":
              return 'mblock@2x.png'
          default:
              return 'docx@2x.png'
      }
  } else {
      return 'docx@2x.png'
  }
}

// 获取资源的说明内容
export function getResourceDesc(item) {
  let size = (item.size / 1024 ).toFixed(2)
  if (item.suffix) {
      switch (item.suffix.toLowerCase()) {
          case ".xls":
              return 'xls文件(' + size + 'KB)'
          case ".txt":
              return 'txt文件(' + size + 'KB)'
          case ".ppt":
          case ".pptx":
              return 'ppt文件(' + size + 'KB)'
          case ".doc":
              return 'doc文件(' + size + 'KB)'
          case ".docx":
              return 'docx文件(' + size + 'KB)'
          case ".mp4":
              return '视频文件(' + (size/1024).toFixed(2) + 'KB)'
          case ".pdf":
              return 'Pdf文件(' + size + 'KB)'
          case ".zip":
              return 'zip文件'
          case ".excel":
              return 'Excel文件'
          case ".mblock":
              return '创客源代码' + size + 'KB'
          default:
              return ''
      }
  } else {
      return ""
  }
}

// 获取学段名称
export function getStageName(keys) {
  if (!keys || !keys.length) {
     return "不限学段"
  }
  let result = ""
  keys.map(key => {
    let list = store.state.dict.dict.find(r => r.key == "busi_course_stage")
    let item = list && list.value && list.value.find(r => r.value == key)
    if (item) {
        result += (result == "" ? "" : " | ") + item.label
    }
  })

  return result ? result : "不限学段"
}

