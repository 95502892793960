// 首页
export const index = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/index/Index.vue'),
        meta: { requiresAuth: false}
    },
    {
        path: '/mobile',
        name: 'MobileIndex',
        component: () => import('@/views/index/IndexM.vue'),
        meta: { requiresAuth: false}
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/Index.vue'),
        meta: { requiresAuth: false }
    },







]