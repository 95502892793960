const state = {
    menuList: [],
}

const mutations = {
  setMenuList: function(state, menuList) {
    state.menuList = menuList
  },

}

export default {
  namespaced: true,
  state,
  mutations,
}