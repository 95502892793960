import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=03d563c2&scoped=true"
import script from "./PageHeader.vue?vue&type=script&lang=js"
export * from "./PageHeader.vue?vue&type=script&lang=js"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=03d563c2&prod&scoped=true&lang=scss"
import style1 from "./PageHeader.vue?vue&type=style&index=1&id=03d563c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/@vue+cli-service@5.0.8/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d563c2",
  null
  
)

export default component.exports