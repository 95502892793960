// 新闻 相关路由
export const news = [
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/news/Index.vue'),
        meta: { requiresAuth: false },
        redirect: '/news/list',
        children: [
            {
                path: 'list',
                name: 'NewsList',
                component: () => import('@/views/news/List.vue'),
            },
            {
                path: 'detail/:id',
                name: 'NewsDetail',
                component: () => import('@/views/news/Detail.vue'),
            },
        ]
    },
    {
        path: '/mobile/news',
        name: 'MobileNews',
        component: () => import('@/views/news/IndexM.vue'),
        meta: { requiresAuth: false },
        redirect: '/mobile/news/list',
        children: [
            {
                path: 'list',
                name: 'MobileNewsList',
                component: () => import('@/views/news/ListM.vue'),
            },
            {
                path: 'detail/:id',
                name: 'MobileNewsDetail',
                component: () => import('@/views/news/DetailM.vue'),
            },
        ]
    }
]