import request from '@/utils/request'

// 数据字典信息


// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: `/system/dict/data/type/${dictType}`,
        method: 'get',
        headers: {
            serialize: false
        }
    })
}