import router from '@/router/index.js'
import store from '@/store'
import { isMobile } from '@/utils/index'

router.beforeEach((to, from, next) => {
    if (isMobile() && to.path.indexOf("/mobile") == -1) { // 移动端
        next({
            path: "/mobile" + to.path,
            query: to.query
        })
    } else if (!isMobile() && to.path.indexOf("/mobile") > -1) { // PC端
        next({
            path: to.path.replace("/mobile", ""),
            query: to.query
        })
    }
    next()



    // if (to.matched.some(r => r.meta.requiresAuth) || to.meta.requiresAuth) {
    //     // 需要token的页面在未登录状态下默认跳转到首页
    //     if (!store.getters.token) {
    //         next({
    //             path: '/'
    //         })
    //     } else {
    //         let flag = false
    //         let menuList = store.state.menu.menuList
    //         function findMenu(arr) {
    //             arr.map(item => {
    //                 if (item.url == to.path) {
    //                     flag = true
    //                 }
    //                 if (item.children) {
    //                     findMenu(item.children)
    //                 }
    //             })
    //         }
    //         findMenu(menuList)
    //         if (flag) {
    //             next()
    //         } else {
    //             next(menuList[0].url)
    //         }
    //     }
    // } else {
    //     next()
    // }
})
