import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import getters from './getters'
import user from './modules/user'
import menu from './modules/menu'
import dict from './modules/dict'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    menu,
    dict
  },
  getters,
  plugins: [createPersistedstate()]
})

export default store